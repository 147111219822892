<template>
  <div class="project-vacancy-select">
    <template v-if="projects.length" v-for="project in projects">
      <ProjectVacancySelectItem
          v-if="!selectedProject || selectedProject === project.id"
          :key="project.id"
          :project="project"
          :selected="selectedProject === project.id"
          @select="selectedProject = project.id"
          @cancel="selectedProject = null"
          v-model="selected"
      ><slot /></ProjectVacancySelectItem>
    </template>
    <div v-else class="no-items">
      <div class="title">Вы пока что не опубликовали ни один проект</div>
    </div>
  </div>
</template>

<script setup>
import { getAllMyProjects } from '~/api/projects'

const props = defineProps({
  modelValue: [String, Number],
  project: [String, Number]
})
const emit = defineEmits(['update:modelValue', 'update:project'])

const selectedProject = computed({
  get: () => props.project,
  set: value => emit('update:project', value)
})
const selected = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})

const invitableProjects = inject('invitableProjects', () => null)
const { data: projects, pending } = await useAsyncData(async () => invitableProjects?.data.value ?? await getAllMyProjects(), { transform: d => d.data, watch: [() => invitableProjects?.data.value] })

watchImmediate(selected, vid => vid && (selectedProject.value = projects.value.find(p => p.vacancies.some(v => v.id === vid))?.id))
watch(selectedProject, pid => {
  const project = projects.value.find(p => p.id === pid)
  if (!project?.vacancies.some(v => v.id === selected.value))
    selected.value = project?.vacancies.length === 1 ? project.vacancies[0].id : null
})
</script>

<style scoped lang="scss">
.project-vacancy-select {
  overflow-x: hidden;
  overflow-y: auto;

  @media (max-width: 920px) {
    &:not(:first-child) {
      padding-top: 8px;
    }
    background: var(--color-bg-secondary);
  }
}
</style>
