<template>
  <BaseButton
    color-primary
    v-bind="$attrs"
    :done="specialist.status_owner_spec === 'request'"
    :class="{ pending }"
  >
    <ContactIcons v-if="!compact" :keys="specialist.available_contacts" />
    <span>{{ actionText }}</span>
    <i v-if="specialist.status_owner_spec === 'specialist-accepted'">{{ specialist.contacts_price }}₽</i>
  </BaseButton>

  <AuthModal :shown="registerModal" title="Авторизуйтесь, чтобы запросить контакты" @close="registerModal = false" />
  <BaseModal
      title="Пригласить специалиста"
      :shown="requestModal"
      @close="requestModal = false"
      wide
      align-top
      close-button
  >
    <template v-if="!isMobile || !projectId">
      <div class="request__header">
        <h1 class="request__title">Выберите проект на который вы хотите позвать специалиста</h1>
        <p class="request__desc">Специалистам важно знать детали проекта, который вы хотите им предложить</p>
        <MobileOnly><MentoringInfo class="request__specialist" v-bind="specialist" sub="Специалист" /></MobileOnly>
      </div>
      <Tabs class="request__tabs" :fluid="isMobile" :tabs="[
        { name: 'Открытые' },
        { name: 'Создать проект' }
      ]" v-model="tab" />
    </template>
    <ProjectVacancySelect class="request__select" v-if="tab === 0" v-model:project="projectId" v-model="vacancyId">
      <MentoringInfo class="request__specialist" v-bind="specialist" sub="Специалист" />
    </ProjectVacancySelect>
    <ProjectForm
      class="request__form"
      v-else-if="tab === 1"
      v-model="form"
      v-model:step="step"
      :errors="errors"
    />

    <template #navbar>
      <DesktopOnly>
        <MentoringInfo v-bind="specialist" sub="Специалист" />
        <BaseButton color-primary :class="{ disabled: tab !== 1 ? !vacancyId : !!errors?.vacancies || !isMainFilled, pending }" @click="submit" :stretch="isMobile">
          <span>{{ tab === 1 ? 'Создать проект' : 'Запросить контакты' }}</span>
          <ContactIcons v-if="tab !== 1" :keys="specialist.available_contacts" />
        </BaseButton>
      </DesktopOnly>
      <MobileOnly>
        <template v-if="tab === 1">
          <BaseButton v-if="step > 0" small transparent @click="step -= 1"><IconChevronLeft class="icon" />Назад</BaseButton>
          <div v-else />

          <BaseButton v-if="step === 1" small color-primary @click="submit" :class="{ disabled: !!errors?.vacancies }">Создать проект</BaseButton>
          <BaseButton v-else small transparent @click="step += 1" :disabled="!isMainFilled">Вперед<IconChevronRight class="icon" /></BaseButton>
        </template>
        <BaseButton v-else color-primary stretch @click="submit" :class="{ disabled: !vacancyId, pending }">Запросить контакты</BaseButton>
      </MobileOnly>
    </template>
  </BaseModal>
  <ClientOnly><PromptModal @provide="prompt = $event"/></ClientOnly>
</template>

<script setup>
import { createProject, openSpecialistContacts, sendRequestToSpecialistForVacancy } from '~/api/projects'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-vue'

const props = defineProps({
  specialist: Object,
  compact: Boolean
})
const emit = defineEmits(['update:contacts', 'update:status'])

const { isMobile } = useBreakpoints()
const router = useRouter()
const user = inject('user')
const updateUserInfo = inject('updateUserInfo')
const prompt = ref()
const registerModal = ref(false)
const requestModal = ref(false)

const actionText = computed(() => ({
  request: 'Контакты запрошены',
  'specialist-accepted': 'Показать контакты'
})[props.specialist.status_owner_spec] ?? 'Запросить контакты')

async function onClick () {
  if (!user.value) return registerModal.value = true
  if (props.specialist.status_owner_spec === 'specialist-accepted') {
    if (props.specialist.contacts_price > user.value.coins_total) {
      if (
          await prompt.value({
            title: `У вас недостаточно средств на балансе, чтобы получить контакт`,
            description: `Стоимость одного контакта – ${props.specialist.contacts_price}₽`,
            accept: 'Пополнить баланс'
          })
      ) await router.push({ name: 'pay-form' })
      return
    }

    return submit(true)
  }

  vacancyId.value = null
  requestModal.value = true
}

const invitableProjects = inject('invitableProjects', () => null)
const tab = ref(0)
const projectId = ref(null)
const vacancyId = ref(null)

const {
  form,
  errors,
  loading: pending,
  submit
} = useForm(
  async (data, open = false) => {
    if (open === true) {
      emit('update:contacts', (await openSpecialistContacts(props.specialist.id))?.data?.contacts)
      updateUserInfo()
      return
    }

    if (tab.value !== 1) {
      await sendRequestToSpecialistForVacancy(props.specialist.id, vacancyId.value)
      emit('update:status', 'request')
      requestModal.value = false
      return
    }

    // if (!data.vacancies?.some(v => v.request_specialists)) return { errors: { vacancies: 'Выберите вакансию, на которую вы хотите позвать специалиста' } }
    return await createProject(data)
  },
  data => {
    if (!data?.id) return

    invitableProjects?.refresh()
    form.value = { vacancies: [] }
    tab.value = 0
    step.value = 0
    projectId.value = null
    vacancyId.value = null
  }
)
form.value.vacancies = []

// watch(() => form.value.vacancies?.some(v => v.request_specialists), value => value && (delete errors.value.vacancies))

const step = ref(0)
const isMainFilled = computed(() => form.value.title && form.value.description)
</script>

<style scoped lang="scss">
i {
  font-style: inherit;
  font-weight: 500;
}

.request {
  &__header {
    margin-bottom: 24px;
    @media (max-width: 920px) {
      margin-bottom: 16px;
    }
  }
  &__title {
    font-size: 28px;
    font-weight: 900;
    line-height: 38px;
    color: var(--color-elements-primary);
    @media (max-width: 920px) {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
  }
  &__desc {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--color-elements-secondary);
    margin-top: 8px;
    @media (max-width: 920px) {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }
  &__specialist {
    margin-top: 16px;
  }
  &__tabs {
    margin-bottom: 32px;
    @media (max-width: 920px) {
      margin-bottom: 0;
    }
  }

  @media (max-width: 920px) {
    &__tabs, &__select {
      margin-left: -15px;
      width: calc(100% + 30px);
    }
    &__form {
      margin-top: 8px;
    }
  }
}
</style>
