<template>
  <BaseModal close-button @close="emit('close')">
    <div class="auth-modal">
      <h3 v-if="title" class="modal__title">{{ title }}</h3>
      <p v-if="description" class="modal__desc">{{ description }}</p>
      <Tabs bottom fluid :tabs="[
        { name: 'Войти' },
        { name: 'Создать аккаунт' }
      ]" v-model="tab" class="auth-modal__tabs" />
      <LoginForm v-if="tab === 0" class="auth-modal__form" @success="emit('close')" />
      <RegisterForm v-else-if="tab === 1" class="auth-modal__form" />
    </div>
  </BaseModal>
</template>

<script setup>
const props = defineProps({
  title: String,
  description: String
})
const emit = defineEmits(['close'])

const tab = ref(0)
</script>

<style scoped lang="scss">
.auth-modal {
  &__tabs {
    margin-top: 16px;
  }
  &__form {
    margin-top: 32px;
    @media (max-width: 920px) {
      margin-top: 24px;
    }
  }
}
</style>
