<template>
  <ProjectSearchResultCard
    class="project-vacancy-select-item"
    :class="{ 'project-vacancy-select-item--active': selected }"
    :link="false"
    :project="project"
  >
    <template #default>
      <ProjectVacancySelectVacancies
        v-if="selected && !isMobile"
        :vacancies="project.vacancies"
        v-model="model"
      />
    </template>
    <template #action>
      <BaseButton v-if="selected" outlined @click="emit('cancel')">Сменить проект</BaseButton>
      <BaseButton v-else color-primary @click="emit('select')" :class="{ disabled: project.is_archive || !project.is_accepted }">Выбрать проект</BaseButton>
    </template>
  </ProjectSearchResultCard>
  <ProjectVacancySelectVacancies
      v-if="selected && isMobile"
      :vacancies="project.vacancies"
      v-model="model"
  ><slot /></ProjectVacancySelectVacancies>
</template>

<script setup>
import { IconClock } from '@tabler/icons-vue'

const props = defineProps({
  project: Object,
  selected: Boolean,
  modelValue: [String, Number]
})
const emit = defineEmits(['update:modelValue', 'select', 'cancel'])

const { isMobile } = useBreakpoints()

const model = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})
</script>

<style lang="scss">
.project-vacancy-select-item {
  &--active {
    @media (min-width: 921px) {
      border: 2px solid var(--color-primary) !important;
    }
  }
}
</style>
