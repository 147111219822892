<template>
  <div class="project-vacancy-select-vacancies">
    <h3 class="project-vacancy-select-vacancies__title">Выберите вакансию, на которую вы хотите позвать специалиста:</h3>
    <slot />
    <ChipList
        class="project-vacancy-select-vacancies__list"
        :items="vacancies.map(v => ({ id: v.id, name: v.title }))"
        select
        v-model="model"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  vacancies: Array,
  modelValue: [String, Number]
})
const emit = defineEmits(['update:modelValue'])

const model = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})
</script>

<style scoped lang="scss">
.project-vacancy-select-vacancies {
  margin-top: 16px;

  &__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
    @media (max-width: 920px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  &__list {
    margin-top: 16px;
    @media (max-width: 920px) {
      margin-top: 24px;
    }
  }

  @media (max-width: 920px) {
    margin-top: 8px;
    padding: 16px 15px;
    background: var(--color-bg);
  }
}
</style>
